import axios from 'axios';
import { env } from '../../lib/config/environment';


const { LOCAL_API_URL } = process.env;
console.log("LOCAL_API_URL");
// const API_URL = 'https://app.chibakenkita.com/v1';

const API_URL = env.API_URL;

//export LOCAL_API_URL=http://localhost:3001 

export async function serverRequestJwt(postData: any) {
  const jwtInfo = localStorage.getItem("jwt");
  try {
    const response = await axios.post(API_URL + '/api', postData, {
      headers: { authorization: 'JWT ' + jwtInfo },
    });
    // console.log(response);
    return response.data.result;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function serverRequest(postData: any) {
  try {
    const response = await axios.post(API_URL + '/api', postData);
    //console.log(response);
    return response.data.result;
  } catch (error) {
    console.log(error);
    return null;
  }
}