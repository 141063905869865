import { FC, useState, useEffect } from 'react'
// import { DemoBlock } from 'demos'
import { Button, Divider, Grid, ImageUploader, SafeArea, Space, TextArea, Toast, Dialog, Collapse, Image, Ellipsis, ImageViewer } from 'antd-mobile'
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader'

import { mockUpload } from './utils'
import { Block } from '../common';
import { uploadImages, getUploadImages, updateUploadImage } from "./../services/api/images";
import { isInt32Array } from 'util/types';


export const MyHistory: FC = () => {
    const [fileList, setFileList] = useState<ImageUploadItem[]>([])
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);

    const [initData, setInitData] = useState([] as any);
    const [visible, setVisible] = useState(false);
    const [img, setImg] = useState("");
    const [id, setId] = useState("");

    const maxCount = 3


    useEffect(() => {
        init();

    }, []);

    const init = async () => {
        const res = await getUploadImages();
        if (res && res.success) {
            setInitData(res.data);

        }
    }

    const renderFooter = (image: string) => {
        return (
            <div className="footer">
                <div
                    className="footerButton"

                    onClick={async () => {
                        console.log('Loading...');
                        try {
                            const res = await updateUploadImage({
                                id,
                                status: "Deleted"
                            });
                            if (res && res.success) {
                                let tempData = initData;

                                tempData.map((d: any) => {
                                    d.images.map((i: any) => {
                                        if (i.id === id) {
                                            i.status = "Deleted"
                                        }
                                    })
                                });

                                setInitData([...tempData]);
                                setVisible(false);
                                Toast.show({
                                    icon: 'success',
                                    content: 'Delete Successfully',
                                })
                            }
                        } catch (error) {
                            Toast.show({
                                icon: 'fail',
                                content: 'Delete Failed',
                            })
                        }
                    }
                    }
                >
                    Delete
                </div>
            </div >
        )
    }

    const histories = () => {
        return (
            <Collapse>
                {
                    initData.filter((i2: any) => i2.images.filter((i3: any) => i3.status === "New").length > 0).map((i: any) => {
                        return (<Collapse.Panel key={i.no} title={"No: " + i.no}>

                            <Ellipsis direction='middle' content={i.description} />
                            <div className={"imagesContainer"}>
                                <Space wrap >
                                    {
                                        i.images.filter((i1: any) => i1.status !== "Deleted").map((ii: any) => {
                                            return (<Image src={ii.image}
                                                onClick={
                                                    () => {
                                                        setImg(ii.image);
                                                        setId(ii.id);
                                                        setVisible(true);
                                                        console.log(ii);
                                                    }
                                                }
                                            />)
                                        })
                                    }
                                </Space>
                            </div>
                        </Collapse.Panel>)

                    })
                }

            </Collapse>
        )
    }



    return (
        <>

            <Space direction='vertical' style={{ width: '100%' }}>

                {histories()}
                <ImageViewer
                    image={img}
                    visible={visible}
                    onClose={() => {
                        setVisible(false)
                    }}
                    renderFooter={renderFooter}
                />
            </Space>

            {/* <Space direction='vertical' style={{ width: '100%', padding: '10px' }}>
                <Block title='Images'>
                    <ImageUploader
                        style={{ '--cell-size': '80px' }}
                        value={fileList}
                        onChange={setFileList}
                        upload={mockUpload}
                        multiple={true}

                    />
                </Block>
                <Block title='Description'>
                    <TextArea
                        value={description}
                        placeholder='input description'
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        onChange={setDescription}
                    />
                </Block>
                <Block title=''>
                    <Button block color='primary' size='large' onClick={upload} loading={loading}>
                        Upload
                    </Button>
                </Block>

                <div style={{ background: '#ffcfac' }}>
                    <SafeArea position='bottom' />
                </div>


            </Space> */}

        </>

    )

}