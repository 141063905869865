import React, { FC } from 'react'
import { NavBar, TabBar } from 'antd-mobile'
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  MemoryRouter as Router,
} from 'react-router-dom'
import {
  AppOutline,
  MessageOutline,
  UnorderedListOutline,
  UserOutline,
  UploadOutline,
  CloseShieldOutline
} from 'antd-mobile-icons'


import { Upload } from './pages/Upload'
import { Login } from './pages/Login'
import { MyHistory } from './pages/History'
import './style.css'


const Top: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location;
  let title = "";

  if (pathname === '/home') {
    title = 'Images Upload'
  } else if (pathname === '/history') {
    title = 'Upload History'
  }

  return (
    pathname !== '/login' ? (

      <NavBar back={null}>{title}</NavBar>
    ) : (null)
  )
}


const Bottom: FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { pathname } = location;
  console.log(pathname);

  const setRouteActive = (value: string) => {
    console.log(value)
    if (value === '/logout') {
      history.push('/login');
    } else {
      console.log(value)
      history.push(value)
    }

  }

  const tabs = [
    {
      key: '/home',
      title: 'Upload',
      icon: <UploadOutline />,
    },
    {
      key: '/history',
      title: 'History',
      icon: <UnorderedListOutline />,
    },
    {
      key: '/logout',
      title: 'logout',
      icon: <CloseShieldOutline />,
    },
    {
      key: '/login',
      title: 'login',
      icon: <MessageOutline />,
    },
  ]

  return (
    pathname !== '/login' ? (
      <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
        {tabs.filter(t => t.key !== '/login').map(item => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
        ))}
      </TabBar>) : (null)
  )
}


function App() {

  return (
    <Router initialEntries={['/login']}>
      <div className={"app"}>
        <div className={"top"}>
          <Top />
        </div>


        <div className={"body"}>
          <Switch>
            <Route exact path='/home'>
              <Upload />
            </Route>
            <Route exact path='/login'>
              <Login />
            </Route>
            <Route exact path='/history'>
              <MyHistory />
            </Route>

          </Switch>
        </div>

        <div className={"bottom"}>
          <Bottom />
        </div>
      </div>
    </Router>
  );
}


export default App;
