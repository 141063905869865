import { FC, useState } from 'react'
// import { DemoBlock } from 'demos'
import { Button, Divider, Grid, ImageUploader, SafeArea, Space, TextArea, Toast,Dialog } from 'antd-mobile'
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader'

import { mockUpload } from './utils'
import { Block } from '../common';
import { uploadImages } from "./../services/api/images"; 


export const Upload: FC = () => {
    const [fileList, setFileList] = useState<ImageUploadItem[]>([])
    const [description, setDescription] = useState("");
    const [loading, setLoading] = useState(false);
    const maxCount = 3


    const upload = async(files:any) =>{
        console.log(fileList);
        console.log(description);
        const images = JSON.stringify(fileList.map(f=>f.url));
        setLoading(true);
        try {
            const res = await uploadImages({
                description,
                images,
                status: "New"
            })
            if(res && res.success){
                // Toast.show({
                //     icon: 'success',
                //     content: 'upload successfully',
                //   });

                  Dialog.show({
                    content: res.data.no+' : uploaded successfully',
                    actions: [{
                          key: 'confirm',
                          text: 'OK',
                        }],
                    closeOnAction: true,
                    onAction: (e)=>{
                        setFileList([]);
                        setDescription("");
                    }
                    // onConfirm: () => {
                    //   console.log('Confirmed');
                    //   setFileList([]);
                    //   setDescription("");
                    // },

                  })

            } else {
                Toast.show({
                    icon: 'fail',
                    content: 'upload failed',
                  })
            }
        } catch (error) {
            Toast.show({
                icon: 'fail',
                content: error+'',
              })
        }
        setLoading(false);
        // const base64 = await getBase64(new File([fileList[0].url ], "name", {lastModified: 0}))
        // console.log(base64);
        // Toast.show({
        //     icon: 'success',
        //     content: 'upload successfully',
        //   })
    }

    return (
        <>
            <Space direction='vertical' style={{ width: '100%', padding: '10px' }}>
                    <Block title='Images'>
                        <ImageUploader
                            style={{ '--cell-size': '80px' }}
                            value={fileList}
                            onChange={setFileList}
                            upload={mockUpload}
                            multiple={true}

                        />
                    </Block>
                    <Block title='Description'>
                        <TextArea
                            value={description}
                            placeholder='input description'
                            autoSize={{ minRows: 3, maxRows: 3 }}
                            onChange={setDescription}
                        />
                    </Block>
                    <Block title=''>
                        <Button block color='primary' size='large' onClick={upload} loading={loading}>
                            Upload
                        </Button>
                    </Block>
                
                    <div style={{ background: '#ffcfac' }}>
                        <SafeArea position='bottom' />
                    </div>


            </Space>

        </>

    )

}