// import axios from 'axios';
import { serverRequestJwt, serverRequest} from './requeset';

export async function login(body: any, options?: { [key: string]: any }) {
    const postData = {
        "method": "User.login",
        "params": body
    };
    const result = await serverRequest(postData);
    localStorage.setItem("jwt",result.token);
    return result
  }
  