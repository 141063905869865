import React, { FC, useState } from 'react'
import { Form, Input, Button, Space,Toast,Divider } from 'antd-mobile'
import { AddCircleOutline,EyeInvisibleOutline,EyeOutline} from 'antd-mobile-icons'
import { Block } from '../common';
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    MemoryRouter as Router,
  } from 'react-router-dom'

 import {login } from "./../services/api/user"; 
  
export const Login: FC = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')
    const [visible, setVisible] = useState(false)

    const onFinish = async(values: any) => {
        console.log(values);
        setLoading(true);
        try {
            const res = await login(values);
            if(res && res.status==='ok'){
                history.push('/home');
            } else {
                Toast.show({
                    icon: 'fail',
                    content: 'login failed ,please check your username and password',
                  })
            }
        } catch (error) {
            Toast.show({
                icon: 'fail',
                content: error+'',
              })
        }



        setLoading(false);

    }

    return (

        <Space direction='vertical' style={{ width: '100%', padding: '10px' ,justifyContent:'center',backgroundColor:'white',margin:'100px 50px 100px 50px'}}>
            <div style={{textAlign:'center'}}>
                <img alt="logo" src={"/logo4.png"} width={200} />
            </div>

            <Form
                onFinish={onFinish}
                initialValues={{
                    contacts: [{}],
                }}
                footer={
                    <Button block type='submit' color='primary' size='large' loading={loading}>
                        Login
                    </Button>
                }
                mode='card'
            >
                <Form.Item name={'username'} label='Username'>
                    <Input placeholder='' />
                </Form.Item>
                <Form.Item name={'password'} label='Password'>
                    <div className={"password"}>
                        <Input
                            className={"input"}
                            type={visible ? 'text' : 'password'}
                        />
                        <div className={"eye"}>
                            {!visible ? (
                            <EyeInvisibleOutline onClick={() => setVisible(true)} />
                            ) : (
                            <EyeOutline onClick={() => setVisible(false)} />
                            )}
                        </div>
                    </div>
                </Form.Item>
       

            </Form>
        </Space>

    )
}