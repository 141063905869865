// import { sleep } from 'demos'
import imageCompression from 'browser-image-compression';


export const demoSrc =
  'https://images.unsplash.com/photo-1567945716310-4745a6b7844b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=60';


const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const getBase642 = async (file: any): Promise<string> =>
  new Promise(async (resolve, reject) => {
    const reader = new FileReader();

    console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    const options = {
      maxSizeMB: 0.01,
      maxWidthOrHeight: 1200,
      useWebWorker: true
    }

    const compressedFile = await imageCompression(file, options);

    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);


    reader.readAsDataURL(compressedFile);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });



export async function mockUpload(file: File) {
  //await sleep(3000)
  return {
    url: await getBase64(file),
    file
  }
}

export async function mockUploadFail() {
  //await sleep(3000)
  throw new Error('Fail to upload')
}

const uploadHandle = async (file: any) => {
  //  判断文件大小是否大于300kb，小于则不做处理
  // if (file.size <= 327200) {
  //   return
  // }
  //  返回一个promise对象
  return new Promise(resolve => {
    //  用于读取本地文件的对象
    const reader = new FileReader()
    //  用于存储选中图片的基本信息
    const image = new Image()
    image.onload = (imageEvent) => {
      //  创建canvas标签元素
      const canvas = document.createElement('canvas')
      //  制定绘制的类型 这边指定的是2d类型
      const context = canvas.getContext('2d')
      //  imgQuality 指缩放的比例，我这边设置了0.5,最好用变量去定义，这样容易维护
      const width = image.width * 0.2 // 0.5
      const height = image.height * 0.2// 0.5
      canvas.width = width
      canvas.height = height
      //  清除canvas的矩形内容(将canvas上的东西清除)
      context?.clearRect(0, 0, width, height)
      //  在canvas中,按指定的比例去画出图片
      context?.drawImage(image, 0, 0, width, height)
      //  将canvas画出的图片转成base64
      const dataUrl = canvas.toDataURL(file.type)
      //  把base64 dataUrl格式转换成blob类型（dataUrlToBlob方法在下面）
      //  const blobData = this.dataUrlToBlob(dataUrl,file.type)
      //  返回blobData进行上传
      console.log(dataUrl);
      resolve(dataUrl as string);
    }
    //  读取完文件后，将图片的路径存到新建的image上
    // reader.onload = (e => {image.src = e.target.result})
    // //  根据路径读取选中的文件
    // reader.readAsDataURL(file)
  })
}

//  把base64 dataUrl格式转换为blob类型
// dataUrlToBlob(dataUrl,type){
//   //  使用window的atob方法去解码base64
//   let binary = atob(dataUrl.split(',')[1])
//   let arr = []
//   //  转unicode编码
//   for (let i = 0; i < binary.length; i++) {
//     arr.push(binary.charCodeAt(i))
//   }
//   //  通过unicode编码去创建Blob类型
//   return new Blob([new Uint8Array(arr)],{type})
// }
