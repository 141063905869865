// import axios from 'axios';
import { serverRequestJwt, serverRequest } from './requeset';

export async function uploadImages(body: any, options?: { [key: string]: any }) {
    const postData = {
        "method": "transaction.updateUploadImages",
        "params": body
    };
    const result = await serverRequestJwt(postData);
    return result
}

export async function getUploadImages(options?: { [key: string]: any }) {
    const postData = {
        "method": "transaction.getUploadImagesForAPP",
        "params": {}
    };
    const result = await serverRequestJwt(postData);
    return result
}


export async function updateUploadImage(body: any) {
    const postData = {
        "method": "transaction.updateUploadImage",
        "params": body
    };
    return await serverRequestJwt(postData);
}
